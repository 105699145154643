
.link:hover {
  color: #1890ff;
}
.ant-menu-horizontal {
  /* border-bottom: 1px solid #fff !important; */
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  /* border-bottom: 1px solid #fff !important; */
}
.ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-item:hover {
  /* border-bottom: 1px solid #1890ff !important; */
}