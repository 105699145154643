/* https://colorhunt.co/palette/189676 */
.ant-table-small .ant-table-thead
{
  background-color: #726a95 !important;
}

.ant-table-small .ant-table-thead th
{
  color: #ffffff;
}

.ant-table-title {
  background-color: #a0c1b8 !important;
}

.ant-table-footer {
  background-color: #f4ebc1 !important;
}

.ant-table-footer .ant-table-small .ant-table-tbody > tr:hover > td {
  background: unset !important;
}

.ant-table-small .ant-table-tbody > tr:hover > td {
  background: #e2e1e9 !important;
}

.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td {
  padding: 6px 8px !important;
}

/* .ant-table-small .ant-table-tbody > tr:nth-child(2n) > td {
  background-color: #f0f0f4;
} */



/* .ant-descriptions.ant-descriptions-small.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #726a95 !important;
  color: #ffffff;
} */