.ant-table-body {
  margin: 0 !important;
}

.ant-table-footer {
  padding: 0 !important;
}

.ant-table-footer .ant-table,
.ant-table-footer .ant-table-content {
  border: 0 !important;
}

.custom-table .ant-table-tbody > tr > td {
  border-bottom: none;
}

.ant-input:focus {
  background-color: #c3e2ff;
}

/* .custom-list .ant-list-item {
  padding: 4px;
}

.custom-list .ant-list-item.selected {
  background: #e6f7ff;
}

.custom-list .ant-list-item.selected .ant-list-item-meta-title {
  color: #1890ff;
}

.custom-list .ant-list-item:hover {
  cursor: pointer;
}

.custom-list .ant-list-item:hover .ant-list-item-meta-title {
  color: #1890ff;
} */
