.ant-layout-header,
.ant-layout-header .ant-menu
{
  background-color: #4e64b7 !important;
}

.ant-layout-header .ant-menu,
.ant-layout-header .user,
.ant-layout-header .ant-menu-item a
{
  color: #ffffff !important;
}

.ant-layout-header .ant-menu
{
  border-color: #4e64b7;
}

.app-name-title > a {
  color: #ffffff !important;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover, 
.ant-menu-horizontal > .ant-menu-item-active, 
.ant-menu-horizontal > .ant-menu-submenu-active, 
.ant-menu-horizontal > .ant-menu-item-open, 
.ant-menu-horizontal > .ant-menu-submenu-open, 
.ant-menu-horizontal > .ant-menu-item-selected, 
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #ffffff !important;
  border-bottom: 2px solid transparent !important;
  background-color: #5f74be;
}

.ant-menu-submenu-title:hover {
  color: #ffffff !important;
}

/* 5f74be lighter than 4e64b7 1 step by https://www.tutorialrepublic.com/html-reference/html-color-picker.php */
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active {
  background-color: #5f74be !important;
}